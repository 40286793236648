@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./clash-display.css');
@import url('./manrope.css');

.fire-link {
  @apply text-inherit hover:text-inherit visited:text-inherit;
}

/*
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none !important;
  }

  .no-scrollbar {
    -ms-overflow-style: none !important;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */
  }
}

html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  /* Layout */
  width: 420px;
  margin: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* Font */
  font-family: 'halyard-text';
  font-size: 18px;

  background-color: #ffffff;
}

#root {
  width: 420px;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'halyard-text';
  font-size: 18px;
  flex: 1;
}

/* Styles for touch-enabled devices (mobile) */
@media (pointer: coarse) {
  #root {
    width: 100% !important;
    height: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
  }
}

.dark-mode body,
body.dark-mode {
  background-color: #1f2134;
}

body.failscan {
  width: 500 !important;
}

body.trial-height {
  min-height: 600px;
  max-height: 600px;
}

body.regular-height {
  min-height: 430px;
}

body.message-height {
  min-height: 555px;
}

.screen {
  width: 100%;
  flex: 1;
  overflow: hidden;
}

button {
  /* Font */
  font-family: 'halyard-text';
}

/* Styles for touch-enabled devices (mobile) */
@media (pointer: coarse) {
  html,
  body,
  body.failscan,
  body.trial-height,
  body.regular-height {
    width: 100% !important;
    height: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
  }
}

.header {
  padding: 14px 20px 14px 20px;
  background-color: #ffffff;
  flex: 1;
  justify-content: center;
}

.dark-mode .header {
  background-color: #1f2134;
}

.appFreeBanner {
  height: 46px;
  padding: 8px 10px;
  background-color: #00469a;
  border-top: 1px solid #356af3;
  border-bottom: 1px solid #356af3;
  color: #fff;
  font-size: 14px;
  line-height: 1.25;
}

.appFreeContent {
  justify-content: space-between;
  align-items: center;
}

.appFreeBanner .upgrade {
  cursor: pointer;
  background: #f9bd64;
  color: #07091b;
  padding: 6px 12px;
  border-radius: 23px;
  font-weight: 700;
  font-size: 12px;
}

.appFreeBanner .upgraded {
  background: #4ff26a;
  color: #07091b;
  padding: 6px 12px;
  border-radius: 23px;
  font-weight: 700;
  font-size: 12px;
}

.appFreeLeft {
  align-items: center;
  gap: 10px;
}

.appFreeBanner a {
  display: flex;
  color: #fff;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.appFreeBanner svg {
  margin-left: 5px;
  height: 20px;
  width: 20px;
}

.content {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-space-between {
  justify-content: space-between;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.gap-1 {
  gap: 16px;
}
.gap-2 {
  gap: 24px;
}
.gap-3 {
  gap: 32px;
}

.gap-8 {
  gap: 8px;
}
.gap-16 {
  gap: 16px;
}
.gap-24 {
  gap: 24px;
}
.gap-32 {
  gap: 32px;
}

.p-1 {
  padding: 16px;
}
.p-2 {
  padding: 24px;
}
.p-3 {
  padding: 32px;
}

.text-gold {
  color: #ffc200;
}

.text-red {
  color: #f75555;
}

.title {
  font-family: 'eurostile';
  font-size: 28px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #07091b;
}

.dark-mode .title {
  color: #f7f8fc;
}

.subtitle {
  font-size: 16px;
}

.small-text {
  font-size: 10px;
}

.text-center {
  text-align: center;
}

.text {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  color: #373a54;
  width: 264px;
}

.dark-mode .text {
  color: #b8b9d0;
}

.no-dark {
  display: block;
}
.dark {
  display: none;
}

.dark-mode .no-dark {
  display: none;
}
.dark-mode .dark {
  display: block;
}

.footer {
  width: 100%;
  box-shadow: 0px 12px 45px 10px #2b1d6714;
  gap: 4px;
}

.expenses {
  padding: 0 24px;
  gap: 5px;
}

.expenses-item {
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
  color: #565975;
}

.gasExpense {
  padding-right: 2px;
}

.expenses-item span {
  font-size: 13px;
  font-weight: 700;
  line-height: 15.73px;
  text-align: right;
  color: #565975;
}

.dark-mode .expenses-item,
.dark-mode .expenses-item span {
  color: #b8b9d0;
}

.settings-group {
  background-color: #f7f8fc;
  width: 100%;
  border-radius: 6px;
}

.dark-mode .settings-group {
  background: #26293f;
  border: 1px solid #1a1c30;
}

.setting-label {
  display: flex;
  gap: 6px;
  align-items: center;
}

.settings-attrib {
  width: 100%;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.dark-mode .settings-attrib span {
  color: #b8b9d0;
}

.settings-attrib .recommended-simulation-off {
  padding-top: 4px;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 6px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #373a54;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
body.dark-mode .slider:before {
  background-color: #07091b;
}

input:checked + .slider,
.dark-mode input:checked + .slider {
  background-color: #356af3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #356af3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 16px;
  background: #dfe0ec;
}

.dark-mode .slider.round {
  background: #373a54;
}

.slider.round:before {
  border-radius: 50%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  height: 44px;
  border-radius: 100px;
  font-family: 'halyard-text';
  cursor: pointer;
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}

a.button {
  text-decoration: none;
}

.primary-button {
  background-color: #356af3;
  border: 1px solid #356af3;
  color: #ffffff;
}

.primary-button:hover {
  box-shadow: 0 2px 8px 0 #0376c933;
}

.primary-button:active {
  background-color: #245ae2;
  border: 1px solid #245ae2;
}

.secondary-button {
  background-color: #ffffff;
  border: 1px solid #dfe0ec;
  color: #26293f;
}

.secondary-button:hover {
  box-shadow: 0 2px 8px 0 #b1b1b133;
}

.secondary-button:active {
  background-color: #f5f5f5;
  border: 1px solid #d0d1dd;
  color: #26293f;
}

.disable-simulation-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  height: 22px;
  border-radius: 50px;
  font-family: 'halyard-text';
  cursor: pointer;
  flex: 1;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}

.enable-simulation-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  height: 22px;
  border-radius: 50px;
  font-family: 'halyard-text';
  cursor: pointer;
  flex: 1;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  background-color: #5c5c5c33;
}

.dark-mode .enable-simulation-button {
  background-color: #92929233 !important;
}

.dark-mode .secondary-button {
  background: #111325;
  border: 1px solid #111325;
  color: #dfe0ec;
}

.dark-mode .secondary-button:hover {
  box-shadow: 0 2px 8px 0 #5c5c5c33;
}

.transaction-divider {
  width: 100%;
  height: 25px;
}

.transaction-divider > div {
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.dark-mode .transaction-divider > div {
  background-color: #373a54;
}

.transaction-divider svg {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

#translator-screen .header-wrapper {
  box-shadow: 0px 4.58px 4.58px 0px #0000000a;
}
#translator-screen .tag-version {
  background: #f1f7ff;
  border: 1px solid #c5e0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 4px 8px;
  border-radius: 6px;
}

.dark-mode #translator-screen .tag-version {
  background: #030525;
  border: 1px solid #00469a;
}

#translator-screen .tag-version span {
  font-size: 12px;
  font-weight: 600;
  line-height: 16.92px;
  text-align: center;
  color: #356af3;
}

#translator-screen .header-right {
  display: flex;
  align-items: center;
  gap: 9.16px;
}

#translator-screen .free-dark-mode {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f8fc;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  border: 1px solid #dfe0ec;
  cursor: pointer;
}
.dark-mode #translator-screen .free-dark-mode {
  background-color: #111325;
  border: 1px solid #26293f;
}

#translator-screen .free-dark-mode svg {
  fill: #8789a6;
}

.dark-mode #translator-screen .free-dark-mode svg {
  fill: #565975;
}

#translator-screen .interacting {
  padding: 16px 24px;
  gap: 24px;
  flex: 1;
  overflow: auto;
}
#translator-screen .interacting::-webkit-scrollbar {
  width: 6px;
}

#translator-screen .interacting::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #f7f8fc;
}

#translator-screen .interacting::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #dfe0ec;
}

.dark-mode #translator-screen .interacting::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #151830;
}

.dark-mode #translator-screen .interacting::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #111325;
}

.interacting-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom: 0.57px solid #dfe0ec;
}

.dark-mode .interacting-header {
  border-bottom: 0.57px solid #373a54;
}

.interacting-content {
  gap: 24px;
}

.interacting-title {
  color: #8789a6;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.interacting-domain-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.interacting-domain {
  background: #f7f8fc;
  border: 1px solid #dfe0ec;
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 11.45px;
  font-weight: 600;
  line-height: 16.14px;
  text-align: center;
  color: #565975;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.interacting-domain-wrapper svg {
  height: 16px;
  width: 16px;
}
.interacting-domain-wrapper a img {
  width: 16px;
}

.dark-mode .interacting-domain {
  background: #26293f;
  border: 1px solid #1a1c30;
  color: #f7f8fc;
}

.interacting-row-header {
  font-size: 11.45px;
  font-weight: 400;
  line-height: 13.86px;
  text-align: left;
  color: #565975;
}

.dark-mode .interacting-row-header {
  color: #f7f8fc;
}

.transaction-type {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.transaction-type b {
  font-weight: 700;
}

.interacting-row {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.interacting-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.interacting-item {
  background: #f7f8fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  border-radius: 6px;
}

.dark-mode .interacting-item {
  background: #26293f;
  border: 1px solid #1a1c30;
  color: #dfe0ec;
}

.interacting-item-nothing {
  justify-content: center;
  padding: 17px;
}

.interacting-item-label {
  display: flex;
  align-items: center;
  gap: 12px;
}
.interacting-item-label div:last-child {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
}

.dark-mode .interacting-item-label div:last-child {
  color: #dfe0ec;
}

.interacting-item-label .interacting-item-token,
.interacting-item-label .interacting-item-nft {
  border: 1px solid #dfe0ec;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.interacting-item-token-symbol {
  display: flex;
  align-items: center;
  gap: 6px;
}

.interacting-item-token {
  border-radius: 50%;
}
.interacting-item-nft {
  border-radius: 4px;
}
.interacting-item-nft img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.interacting-item-value {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.interacting-item-value div:first-child {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-align: right !important;
}

.interacting-item-value div:last-child {
  color: #373a54;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  text-align: right;
}

.dark-mode .interacting-item-value div:last-child {
  color: #dfe0ec;
}

.sending-items .interacting-item-value div:first-child {
  color: #ff4d4d;
}
.receiving-items .interacting-item-value div:first-child {
  color: #27c440;
}

.approving-items .interacting-item-value .approving-value {
  color: #356af3;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: right;
  text-transform: capitalize;
}

.interacting .settings-attrib .flex-1 {
  display: flex;
  align-items: center;
  gap: 6px;
}

.pro-settings {
  padding: 24px 0;
  border-top: 0.57px solid #dfe0ec;
}

.dark-mode .pro-settings {
  border-top: 0.57px solid #373a54;
}

#translator-screen .buttons {
  padding: 16px 24px;
  gap: 9.16px;
}

.message-wrapper {
  display: flex;
  flex-direction: column;
  background: #f7f8fc;
  padding: 24px;
  border-radius: 4px;
  align-items: center;
  gap: 12px;
}

.dark-mode .message-wrapper {
  background: #26293f;
  border: 1px solid #1a1c30;
  color: #b8b9d0;
}

.message-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: #373a54;
}

.dark-mode .message-title {
  color: #dfe0ec;
}

.message-icon {
  background: #ffffff;
  border: 1.2px solid #dfe0ec;
  border-radius: 4.8px;
  display: flex;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
}

.dark-mode .message-icon {
  background-color: #373a54;
  border: 1.2px solid #26293f;
}

.message-text {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
}

.message-text a {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #356af3;
  text-decoration: none;
}

.skeleton {
  padding: 16px 24px;
}

.skeleton-item {
  background-color: #f7f8fc;
}
.dark-mode .skeleton-item {
  background-color: #26293f;
}

.skeleton-divider {
  width: 100%;
  height: 0.57px;
  background-color: #f7f8fc;
}

.dark-mode .skeleton-divider {
  background-color: #26293f;
}
